import "./tokenomics.css"

function TokenomicsSession() {
  return (
    <div className="TokenomicsMain">
      <div className="TokenomicsContainer">
        <h1 className="TokenomicsTitle">Supply Breakdown</h1>
        <div className="TokenomicsContent">
          <div className="TokenomicsContentImageDiv">
            <img 
              src="images/tokenomics_1.png"
              className="TokenomicsContentImage"
              alt="Tokenomics"
            />
          </div>
          <div className="TokenomicsContentDetail">
            <h1 className="TokenomicsDetailTitle">Total Supply: 1 Billion Tokens</h1>
            <ul className="TokenomicsDetailList">
              <li className="TokenomicsDetailListItemLV1">50% of Supply to Presale Participants</li>
              <li className="TokenomicsDetailListItemLV1">15% of Supply to LP</li>
              <li className="TokenomicsDetailListItemLV1">15% of Tokens for Exchanges + Market Making</li>
              <ul className="TokenomicsDetailChildList">
                <li className="TokenomicsDetailListItemLV2">10% for CEX</li>
                <li className="TokenomicsDetailListItemLV2">5% for Market Makings</li>
              </ul>
              <li className="TokenomicsDetailListItemLV1">10% Marketing</li>
              <ul className="TokenomicsDetailChildList">
                <li className="TokenomicsDetailListItemLV2">5% Strategic Partnerships</li>
                <li className="TokenomicsDetailListItemLV2">5% Advertisement</li>
              </ul>
              <li className="TokenomicsDetailListItemLV1">10% Team/Advisors</li>
              <ul className="TokenomicsDetailChildList">
                <li className="TokenomicsDetailListItemLV2">5% Team (Vested for 1 Year)</li>
                <li className="TokenomicsDetailListItemLV2">5% Advisors</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="TokenomicsContainer">
        <h1 className="TokenomicsTitle">Presale Breakdown</h1>
        <div className="TokenomicsContent">
          <div className="TokenomicsContentImageDiv">
            <img src="images/tokenomics_2.png" alt="Tokenomics" className="TokenomicsContentImage" />
          </div>
          <div className="TokenomicsContentDetail">
            <ul className="TokenomicsDetailList">
              <li className="TokenomicsDetailListItemLV1">50% of Raise to LP</li>
              <li className="TokenomicsDetailListItemLV1">50% of Raise for expenses/development/distribution</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenomicsSession
