import "./question.css"

function QuestionSession() {
  return (
    <div className="QuestionMain">
      <h3 className="QuestionIntroduction">
        TRADE YOUR QUESTIONS FOR ANSWERS
      </h3>
      <div className="QuestionDiv">
        <div className="QuestionEachDiv">
          <h4 className="QuestionText">
            WHO IS COOP?
          </h4>
          <p className="QuestionAnswer">
            COOP is the champion of the Base ecosystem.<br/>Protecting, vibing and growing the $COOP community.
          </p>
        </div>
        <div className="QuestionEachDiv">
          <h4 className="QuestionText">
            WHAT CHAIN IS $COOP ON?
          </h4>
          <p className="QuestionAnswer">
            $COOP is on Base.
          </p>
        </div>
        <div className="QuestionEachDiv">
          <h4 className="QuestionText">
            HOW CAN I BUY $COOP?
          </h4>
          <p className="QuestionAnswer">
            Make sure you download the Metamask Wallet app or browser extension for you phone. <br/>Simply purchase $COOP using BASE-ETH through a DEX like Uniswap. <br/>Easy!
          </p>
        </div>
      </div>
    </div>
  )
}

export default QuestionSession
