import './App.css'
import HeaderSession from './home/header/header';
import IntroductionSession from './home/introduction/introduction';
import ContractSession from './home/contract/contract';
import RoadmapSession from './home/roadmap/roadmap';
import QuestionSession from './home/question/question';
import SocialSession from './home/social/social';
import TokenomicsSession from './home/tokenomics/tokenomics';

function App() {
  return (
    <div className="App">
      <HeaderSession />
      <IntroductionSession />
      <ContractSession  />
      <RoadmapSession />
      <TokenomicsSession />
      <QuestionSession />
      <SocialSession />
    </div>
  );
}

export default App;
