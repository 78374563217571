import "./introduction.css"

function IntroductionSession() {
  return (
    <div className="IntroductionMain">
      <div className="IntroductionLogoImageDiv">
        <img 
          fetchpriority="high" 
          decoding="async" 
          data-nimg="fill" 
          src="images/banner.svg"
          className="IntroductionLogoImage"
          alt="CoooponBase"
        />
      </div>
      <div className="IntroductionAnnouncementDiv">
        <div className="IntroductionAnnouncementImageDiv">
          <img 
            fetchpriority="high" 
            decoding="async" 
            data-nimg="fill"
            className="IntroductionAnnouncementImage"
            src="images/introduction_announcement_icon.svg" 
            alt=""
          />
        </div>
        <div className="IntroductionAnnouncementTextDiv">
          <h4 className="IntroductionAnnouncementText">
            Hey there, I'm Coop, <br/> champion of the BASE ecosystem.
          </h4>
        </div>
      </div>
    </div>
  )
}

export default IntroductionSession
