import "./social.css"

function SocialSession() {
  return (
    <div className="SocialMain">
      <p className="SocialText">SOCIALS</p>
      <p className="SocialJoin">JOIN THE $COOP COMMUNITY</p>
      <p className="SocialEmail">coop@tokenonbase.com</p>
      <div className="SocialPageDiv">
        <a href="https://t.me/CoopOnBaseOffical" 
          target="_blank" 
          rel="noreferrer" 
          className="SocialPageButton"
        >
          <img 
          fetchpriority="high" 
          decoding="async" 
          data-nimg="fill" 
          src="images/telegram_icon.svg"
          className="SocialPageButtonIcon"
          alt=""
          />
        </a>
        <a href="https://twitter.com/CoopOnBase" 
          target="_blank" 
          rel="noreferrer" 
          className="SocialPageButton"
        >
          <img 
          fetchpriority="high" 
          decoding="async" 
          data-nimg="fill" 
          src="images/twitter_icon.svg"
          className="SocialPageButtonIcon"
          alt=""
          />
        </a>
      </div>
      <div className="SocialBannerDiv">
        <img 
          fetchpriority="high" 
          decoding="async" 
          data-nimg="fill" 
          src="images/banner_white.svg"
          className="SocialBanner"
          alt="CoooponBase"
        />
      </div>
    </div>
  )
}

export default SocialSession
