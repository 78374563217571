import "./roadmap.css"

function RoadmapSession() {
  return (
    <div className="RoadmapMain">
      <div className="RoadmapMainContainer">
        <img
          className="RoadmapImage" 
          src="images/roadmap_main.svg" 
          alt="RoadmapMainContainer" 
        />
      </div>
      <div className="RoadmapBottomContainer">
        <img
          className="RoadmapImage"
          src="images/roadmap_bottom.svg" 
          alt="RoadmapBottomContainer" 
        />
      </div>
    </div>
  )
}

export default RoadmapSession
