import "./header.css"

function HeaderSession() {
  return (
    <div className="HeaderMain">
      <div className="HeaderButton">
        PRESALE
      </div>
    </div>
  )
}

export default HeaderSession
