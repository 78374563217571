import "./contract.css"

function ContractSession() {
  return (
    <div className="ContractMain">
      <h2 className="ContractAddressTxt">
        CONTRACT ADDRESS
      </h2>
      <div className="ContractAddressDiv">
        <input 
          type="text"
          className="ContractAddressInput"
          value="0xf4438e09664c3e077e14c5e940b9a465402d2017"
        />
          <div className="ContractAddressCopyButton">
            COPY
          </div>
      </div>
      <div className="ContractDexToolDiv">
        <a href="https://www.dextools.io/app/en/base/pair-explorer/0xec9259978b2007e190b6f6eb821ad47b9857cd3d?t=1711484819014" 
            target="_blank"
            rel="noreferrer"
            className="ContractDexToolRef">
          <img 
            fetchpriority="high" 
            decoding="async" 
            data-nimg="fill" 
            src="images/dextools.png" 
            className="ContractDexToolImage"
            alt=""
          />
          <div className="ContractDexToolText">
            DEX TOOLS
          </div>
        </a>
      </div>
    </div>
  )
}

export default ContractSession
